import './CreateQuizModule.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import arrow_left from '../../utils/images/arrow_left.svg';
import autumn from '../../utils/images/autumn.png';
import spring from '../../utils/images/spring.png';
import winter from '../../utils/images/winter.png';
import summer from '../../utils/images/summer.png';
import CreateQuizPopup from './CreateQuizPopup';
import api from '../../utils/Api';
import CreateMassQuiz from './CreateMassQuiz';

function CreateQuizModule() {
    const navigate = useNavigate();
    const [createOne, setCreateOne] = useState(true);
    const [isPopupOpened, setPopupOpened] = useState(false);
    const [selectedEmployee, setEmployee] = useState(null);
    const [selectedRespondent, setRespondent] = useState(null)
    const [date, setDate] = useState(null);
    const [type, setType] = useState('');
    const [isSuccess, setSuccess] = useState(false);
    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedSeason, setSeason] = useState(null);
    const [isMassOpened, setMassOpened] = useState(false);

    const getDateFromType = (selectedSeason) => {
        const seasons = {
            '1 Квартал': '-03-31',
            '2 Квартал': '-06-30',
            '3 Квартал': '-09-30',
            '4 Квартал': '-12-15'
        };
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const seasonStartDate = seasons[selectedSeason] || '';
        const formattedDate = `${currentYear}${seasonStartDate}`;
        return `${formattedDate}`;
    };

    const handleSubmit = async () => {
        try {
            const token = localStorage.getItem('jwt');
            const formattedDate = getDateFromType(selectedSeason);
            const body = {
                "staff_id": `${selectedEmployee.id}`,
                "inspector_id": `${selectedRespondent.id}`,
                "quarter": formattedDate,
                "annual_assessment": false
            };

            const res = await api.createQuiz(token, body);
            setSuccess(true);
            setError(false);
        } catch (error) {
            setSuccess(false);
            setError(true);
            const errorMessage = Object.values(error.data).flat().join(' ');
            setErrorMessage(errorMessage);
            console.log(error);
        } finally {
            setEmployee({});
            setRespondent({});
            setDate(null);
        }
    };



    return (
        <>
            <div className='quiz__create__block'>
                <button className="user-info__button-div" onClick={() => navigate('/profile')}>
                    <img className="quiz-module__button" src={arrow_left} alt="" />
                    <p>В профиль</p>
                </button>
                <div className='quiz__create__div'>
                    <div className={`quiz__create__button${createOne ? '_active' : ''}`} onClick={() => setCreateOne(true)}>Одиночное</div>
                    <div className={`quiz__create__button${createOne ? '' : '_active'}`} onClick={() => setCreateOne(false)}>Массовое</div>
                </div>
                {createOne ?
                    <>
                        <div className='quiz__create__form'>
                            <p className='quiz__create__p'>Выбранный сотрудник: <span onClick={() => {
                                setPopupOpened(true)
                                setType('user');
                            }}>{selectedEmployee?.id ? `${selectedEmployee.last_name} ${selectedEmployee.first_name} ${selectedEmployee.surname}` : 'Нажмите чтобы выбрать'}</span></p>
                            <p className='quiz__create__p'>Оценивающий сотрудник: <span
                                onClick={() => {
                                    setPopupOpened(true)
                                    setType('respondent');
                                }}>
                                {selectedRespondent?.id ? `${selectedRespondent.last_name} ${selectedRespondent.first_name} ${selectedRespondent.surname}` : 'Нажмите чтобы выбрать'}
                            </span></p>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                                <p className='quiz__create__p' style={{ marginBottom: 0 }}>Дата создания: </p>
                                <div className='quiz__create__grid-s'>
                                    <div className={`quiz__create__grid-item-s${selectedSeason === '1 Квартал' ? '_active' : ''}`} onClick={() => {
                                        setSeason('1 Квартал')
                                    }}>
                                        <p style={{ fontWeight: 'normal' }}>1 Квартал</p>
                                        <p>Январь - Март</p>
                                    </div>
                                    <div className={`quiz__create__grid-item-s${selectedSeason === '2 Квартал' ? '_active' : ''}`} onClick={() => {
                                        setSeason('2 Квартал')
                                    }}>
                                        <p style={{ fontWeight: 'normal' }}>2 Квартал</p>
                                        <p>Апрель - Июнь</p>
                                    </div>
                                    <div className={`quiz__create__grid-item-s${selectedSeason === '3 Квартал' ? '_active' : ''}`} onClick={() => {
                                        setSeason('3 Квартал')
                                    }}>
                                        <p style={{ fontWeight: 'normal' }}>3 Квартал</p>
                                        <p>Июль - Сентябрь</p>
                                    </div>
                                    <div className={`quiz__create__grid-item-s${selectedSeason === '4 Квартал' ? '_active' : ''}`} onClick={() => {
                                        setSeason('4 Квартал')
                                    }}>
                                        <p style={{ fontWeight: 'normal' }}>4 Квартал</p>
                                        <p>Октябрь - Декабрь</p>
                                    </div>
                                </div>
                            </div>
                            {isSuccess ? <p className='quiz__create__success'>Анкета успешно создана!</p> : null}
                            {isError ? <p className='quiz__create__error'>Произошла ошибка при создании анкеты: {errorMessage}</p> : null}
                            <button className='quiz__create__button-s' onClick={(e) => {
                                e.preventDefault();
                                if (selectedEmployee.id && selectedRespondent.id && selectedSeason) {
                                    handleSubmit();
                                }
                            }}
                                disabled={!selectedSeason || !selectedEmployee || !selectedRespondent}
                            >Создать анкету</button>
                        </div>
                    </>
                    :
                    <>
                        <div className='quiz__create__grid'>
                            <div className='quiz__create__grid-item' onClick={() => {
                                setSeason('1 Квартал')
                                setMassOpened(true);
                            }}>
                                <p style={{ fontWeight: 'normal' }}>1 Квартал</p>
                                <p>Январь - Март</p>
                            </div>
                            <div className='quiz__create__grid-item' onClick={() => {
                                setSeason('2 Квартал')
                                setMassOpened(true);
                            }}>
                                <p style={{ fontWeight: 'normal' }}>2 Квартал</p>
                                <p>Апрель - Июнь</p>
                            </div>
                            <div className='quiz__create__grid-item' onClick={() => {
                                setSeason('3 Квартал')
                                setMassOpened(true);
                            }}>
                                <p style={{ fontWeight: 'normal' }}>3 Квартал</p>
                                <p>Июль - Сентябрь</p>
                            </div>
                            <div className='quiz__create__grid-item' onClick={() => {
                                setSeason('4 Квартал')
                                setMassOpened(true);
                            }}>
                                <p style={{ fontWeight: 'normal' }}>4 Квартал</p>
                                <p>Октябрь - Декабрь</p>
                            </div>
                        </div>
                        <p className='quiz__create__p' style={{ marginTop: '15px' }}>Учтите, массовое создание анкет может длиться несколько минут.</p>
                    </>
                }
            </div>
            <CreateQuizPopup type={type} isPopupOpened={isPopupOpened} setPopupOpened={setPopupOpened} setEmployee={setEmployee} setRespondent={setRespondent} />
            <CreateMassQuiz selectedSeason={selectedSeason} isPopupOpened={isMassOpened} setPopupOpened={setMassOpened} />
        </>
    )
}

export default CreateQuizModule;
