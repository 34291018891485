import server_error from '../utils/images/server_error.png';
function ErrorComponent({ isError }) {
    console.log(isError);
    return (
        <div style={{ display: 'flex', padding: '50px', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', userSelect: 'none', gap: '10px', marginTop: '50px'}}>
            <h2>Что-то пошло не так...</h2>
            <h2>{isError?.status}</h2>
            <img style={{ width: '100px', height: '100px', padding: '10px' }} src={server_error} alt=''></img>
            <div>
                <p style={{ fontSize: '18px', lineHeight: '1.2' }}>{isError?.message}</p>
                <p style={{ fontSize: '18px', lineHeight: '1.2' }}>{isError?.data?.detail}</p>
            </div>

            <a style={{
                padding: '0 10px',
                height: '35px',
                backgroundColor: '#6BC4E8',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textDecoration: 'none',
                color: '#fff',
                borderRadius: '10px',
                marginTop: '10px'
            }}
                href='/profile'>На главную</a>
        </div>
    )
}

export default ErrorComponent;