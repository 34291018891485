import React, { useEffect, useState } from 'react';
import QuestionItem from "./QuestionItem";
import LoadingComponent from "../../utils/LoadingComponent";
import api from '../../utils/Api';
import { useParams } from 'react-router-dom';
import useQuizStore from "../../utils/stores/useQuizStore";
import AnswerItem from '../../modules/InspectorQuizModule/AnswerItem/AnswerItem';
function QuizBody({ }) {
    const [isLoading, setIsLoading] = useState(false);
    const { groupedStaffQuiz } = useQuizStore();


    const answerValuesToLetters = {
        '5.0': 'A',
        '4.0': 'B',
        '3.0': 'C',
        '1.5': 'D',
        '0.0': 'E'
    };

    const renderAnswer = (answer) => {
        const answerKey = parseFloat(answer).toFixed(1);
        return answerValuesToLetters[answerKey] || '?';
    };

    const NotEditableQuestionItem = ({ item }) => {
        return (
            <div className="question-item__div-ne" >
                <div className="question-item__header">
                    <p className="question-item__title">{item.category_name}</p>
                </div>
                {item.answers.map((answer, index) => {
                    const parts = answer.question_description.split(/\([A-Z]\)\s*(?=\S)/);
                    return (
                        <div
                            key={answer.id_answer}
                            id={answer.id_answer}
                            className="question-item__row-ne"
                        >
                            <div className="question-item__row__scoreh" id='final-score'>
                                <p>{answer && answer.question_name}</p>
                                <div className='question-item__score-select' id='no-margin'>
                                    <div className={`${answer.answer ? 'staff-answer' : 'empty-answer'}`} title='Рассчитывается автоматически'>
                                        {answer.answer ? renderAnswer(answer.answer) : '?'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        )
    }

    if (isLoading) return <LoadingComponent />;

    return (
        <>
            {groupedStaffQuiz
                ?.filter(item => item.category_name !== 'Оценка по компетенциям' && item.category_name !== 'Итоговая оценка')
                .map((item, index) => {
                    return <QuestionItem key={index} item={item} />;
                })}
            {groupedStaffQuiz
                ?.filter(item => item.category_name === 'Оценка по компетенциям' || item.category_name === 'Итоговая оценка')
                .map((item, index) => {
                    return <NotEditableQuestionItem key={index} item={item} />;
                })}
        </>
    );
}




export default QuizBody;
