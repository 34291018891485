import React, { useEffect, useRef } from 'react';
import './SuccessQuizPopup.css';
import success from '../../../utils/images/success.svg';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
function SuccessQuizPopup({ isSuccess }) {
    const navigate = useNavigate();
    const popupRef = useRef();

    const queryParams = new URLSearchParams(window.location.search);
    const fromSearch = queryParams.get("fromSearch");

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                navigate('/profile');
            }
        };
        if (isSuccess) {
            document.documentElement.classList.add('body-scroll-lock');
        } else {
            document.documentElement.classList.remove('body-scroll-lock');
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.documentElement.classList.remove('body-scroll-lock');
        };
    }, [isSuccess, navigate]);

    return (
        <>
            {isSuccess &&
                (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ type: 'spring', duration: .7 }}
                        className="success-quiz-popup__layout">
                        <div ref={popupRef} className="success-quiz-popup__body">
                            <img src={success} className='success-quiz-popup__img' alt="Success"></img>
                            <p className='success-quiz-popup__title'>Анкета успешно сохранена.</p>
                            <div style={{display: 'flex', flexDirection: 'row', gap: '15px'}}>
                                <button onClick={() => navigate('/profile')} className='success-quiz-popup__button'>В профиль</button>
                                {fromSearch === 'true' &&
                                    <button
                                        className='success-quiz-popup__button'
                                        onClick={() => navigate('/employee-search')}>Назад к поиску</button>
                                }
                            </div>
                        </div>
                    </motion.div>
                )
            }
        </>
    );
}

export default SuccessQuizPopup;
