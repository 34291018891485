import './ProfileModule.css';
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UserInfo from "../../components/UserInfo/UserInfo";
import ScoreModule from "../ScoreModule/ScoreModule";
import EmployeeModule from "../EmployeeModule/EmployeeModule";
import QuickActions from "../../components/QuickActions/QuickActions";
import LoadingComponent from '../../utils/LoadingComponent';
import ErrorComponent from '../../utils/ErrorComponent';
import api from '../../utils/Api';
import arrow_left from '../../utils/images/arrow_left.svg';
import HRModule from '../HRModule/HRModule';
import { motion } from 'framer-motion';
import NotificationCenter from '../NotificationCenter/NotificationCenter';
import ActualScoreModule from '../ScoreModule/ActualScoreModule';
import useUserStore from '../../utils/stores/useUserStore';
const ProfileModule = ({ userProfile, loadingError, currentUser }) => {
  const { profileId } = useParams();
  const navigate = useNavigate();

  const { isLoading } = useUserStore();

  useEffect(() => {
    document.title = "Pridex Plus | Личный кабинет";
  }, []);


  if (loadingError) {
    return <ErrorComponent />
  }

  return <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', paddingBottom: '30px' }}
      >
        <div className="user-info__div">
          <UserInfo userProfile={userProfile} />
          <QuickActions display={false} userProfile={userProfile} />
        </div>
        <ActualScoreModule />
        {(userProfile.roles && userProfile.roles.length > 0 && userProfile.roles[0].name === 'HR') && <HRModule />}
        <EmployeeModule userProfile={userProfile} isYours={true} />
        <ScoreModule userProfile={userProfile} currentUser={currentUser} />
      </motion.div>
  </>
};

export default ProfileModule;
