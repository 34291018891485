import { AnimatePresence, motion } from 'framer-motion';
export const PageTransition = (props) => {
    return (
      <motion.div
        {...props}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ type: 'spring', duration: .7 }}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', ease: 'easeInOut', marginTop: '50px', padding: '35px 0 35px 0'}}
      >
        {props.children}
      </motion.div>
    )
  }