import { renderAnswer } from "./UtilityFunctions";
import TextareaAutosize from 'react-textarea-autosize';
const NotEditableQuestionItem = ({ item, isCalibrate }) => {
    return (
        <div className="question-item__div-ne">
            <div className="question-item__header">
                <p className="question-item__title">{item.category_name}</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
                {item.answers.map((answer, index) => {
                    return (
                        <>
                            <div
                                key={answer.id_answer}
                                className="question-item__row-ne"
                            >
                                <div className="question-item__row__scoreh" id='final-score'>
                                    <p>{answer && answer.question_name}</p>
                                    <div className='question-item__score-select'>
                                        <div className={`${answer.answer ? 'staff-answer' : 'empty-answer'}`} title='Рассчитывается автоматически'>
                                            {answer.answer ? renderAnswer(answer.answer) : '?'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {isCalibrate && answer.comment.length > 0 &&
                                <div className="question-comment">
                                    <p>{answer.comment}</p>
                                </div>
                            }
                        </>
                    );
                })}
            </div>
        </div>
    )
}

export default NotEditableQuestionItem;