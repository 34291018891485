import { useParams } from "react-router-dom";
import api from "../../utils/Api";
import { useEffect, useState } from "react";
import LoadingComponent from "../../utils/LoadingComponent";
import AnswerItem from "./AnswerItem/AnswerItem";
import useQuizStore from "../../utils/stores/useQuizStore";
import SummaryAnswerItem from "./AnswerItem/SummaryAnswerItem";
import NotEditableQuestionItem from "../QuizModule/utils/NotEditableQuestionItem";
import QuestionItem from "../../components/QuizBody/QuestionItem";
import TextareaAutosize from 'react-textarea-autosize';
import { renderAnswer } from "../QuizModule/utils/UtilityFunctions";
export default function InspectorQuizModule() {
    const { quizId } = useParams();
    const { mergedInspectorQuiz, quiz, isYours, isInspector, groupedInspectorQuiz, handleUpdateFullQuizComment, groupedCalibrateQuiz, currentPeriod } = useQuizStore();

    return (

        <>
            {mergedInspectorQuiz
                ?.filter(item => !(item.category_name === 'Оценка по компетенциям' || item.category_name === 'Итоговая оценка'))
                ?.map((item, index) => {
                    if (quiz.status_doc_quiz_name === 'Оценен руководителем') {
                        return <SummaryAnswerItem key={index} item={item} />
                    } else if (isInspector && !isYours && (currentPeriod === 'inspector' || quiz.status_doc_quiz_name === 'Оценен сотрудником')) {
                        return <AnswerItem key={index} item={item} />;
                    } else if (isYours) {
                        return <SummaryAnswerItem key={index} item={item} />;
                    } else if (!isYours) {
                        return <SummaryAnswerItem key={index} item={item} />;
                    }
                })}
            <div className="answer-item">
                <div className="question-header">
                    <p>Итоговый комментарий сотруднику</p>
                </div>
                {quiz?.status_doc_quiz_name === 'Оценен руководителем' || quiz?.status_doc_quiz_name === 'Завершен' ? (
                    quiz?.comment?.length > 0 ? (
                        <div className="question-comment">
                            <p style={{ color: '#666666' }}>— {quiz.full_name_inspector}</p>
                            <p>{quiz.comment}</p>
                        </div>
                    ) : (
                        <div className="question-comment">
                            <p>Руководитель не оставил комментарий</p>
                        </div>
                    )
                ) : (
                    null
                )}
                {isInspector && (quiz?.status_doc_quiz_name === 'Оценен сотрудником' || quiz?.status_doc_quiz_name === 'Создана') && (
                    <TextareaAutosize
                        type="text"
                        maxRows={5}
                        className="inspector__input"
                        placeholder="Введите комментарий..."
                        value={quiz?.comment?.length > 0 ? quiz.comment : ''}
                        style={{ color: '#000' }}
                        disabled={!isInspector || quiz.status_doc_quiz_name === 'Оценен руководителем' || isYours}
                        onChange={(e) => handleUpdateFullQuizComment(e.target.value)}
                    />
                )}
            </div>

            {quiz?.status_finishing
                ?
                <>
                    {groupedInspectorQuiz
                        ?.filter(item => item.category_name === 'Оценка по компетенциям')
                        ?.map((item, index) => {
                            return <NotEditableQuestionItem key={index} item={item} />;
                        })}
                    {groupedCalibrateQuiz
                        ?.filter(item => item.category_name === 'Итоговая оценка')
                        ?.map((item, index) => {
                            return <NotEditableQuestionItem key={index} item={item} isCalibrate={true} />;
                        })}
                </>
                :
                <>
                    {groupedInspectorQuiz
                        ?.filter(item => item.category_name === 'Оценка по компетенциям' || item.category_name === 'Итоговая оценка')
                        ?.map((item, index) => {
                            return <NotEditableQuestionItem key={index} item={item} />;
                        })}
                </>
            }

        </>
    );
}
