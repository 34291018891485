import React, { useEffect } from "react";
import { Tooltip } from 'react-tooltip';
import './QuizBody.css';
import { motion } from 'framer-motion';
import TextareaAutosize from 'react-textarea-autosize';
import useQuizStore from "../../utils/stores/useQuizStore";
import useWindowDimensions from "../../modules/QuizModule/utils/getWindowsDimensions";

function QuestionItem({ item }) {
    const { handleUpdateComment, handleUpdateAnswer, isYours, isInspector } = useQuizStore();

    const { category_name: categoryName, answers } = item;

    const { height, width } = useWindowDimensions();

    const handleCommentChange = (questionId, e) => {
        if (e && e.target) {
            handleUpdateComment(questionId, e.target.value, 'staff');
        }
    };

    const handleAnswerChange = (e, questionId, value) => {
        e.preventDefault();
        handleUpdateAnswer(questionId, value, 'staff');
    };

    const answerValues = ['5.0', '4.0', '3.0', '1.5', '0.0'];

    const renderDescription = (answer) => {
        const answerOptions = ['5.0', '4.0', '3.0', '1.5', '0.0'];
        const parts = answer.question_description.split(/\([A-Z]\)\s*(?=\S)/);
        const index = answerOptions.indexOf(answer.answer);
        if (index !== -1 && parts.length > index + 1) {
            return (
                <div className="question-item__score__description">
                    <p>{parts[index + 1]}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <div className="question-item__div">
            <div className="question-item__header">
                <p className="question-item__title">{categoryName}</p>
            </div>
            {answers.map((answer, index) => {
                const parts = answer.question_description.split(/\([A-Z]\)\s*(?=\S)/);
                return (
                    <React.Fragment key={answer.id_answer}>
                        <motion.div
                            key={answer.id_answer}
                            id={answer.id_answer}
                            className="question-item__row"
                        >
                            <div className="question-item__row__scoreh">
                                <p>{answer && answer.question_name}</p>
                                <div className='question-item__score-select'>
                                    {['A', 'B', 'C', 'D', 'E'].map((label, idx) => (
                                        <React.Fragment key={label}>
                                            <button
                                                onClick={(e) => handleAnswerChange(e, answer.id_answer, answerValues[idx])}
                                                data-tooltip-content={parts[idx + 1]}
                                                data-tooltip-id={`${answer.question_id}-${index}-${label}-tooltip`}
                                                data-tooltip-place="bottom"
                                                id={`${answer.question_id}-${idx}`}
                                                className={`inspector-button ${parseFloat(answer.answer) == parseFloat(answerValues[idx]) ? 'selected' : ''}`}
                                                disabled={!isYours}
                                                type="button"
                                            >
                                                {label}
                                            </button>
                                            {parseFloat(answer.answer) !== parseFloat(answerValues[idx]) && width > 768 && (
                                                <Tooltip
                                                    id={`${answer.question_id}-${index}-${label}-tooltip`}
                                                    style={{
                                                        maxWidth: '300px',
                                                        borderRadius: '10px',
                                                        backgroundColor: '#fff',
                                                        color: '#000',
                                                        fontSize: '15px',
                                                        boxShadow: '0px 0px 5px 0px #00000066',
                                                        textAlign: 'left',
                                                        fontFamily: 'Circe',
                                                        lineHeight: '1.3'
                                                    }}
                                                    opacity={1}
                                                />
                                            )}
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                            {answer.answer && renderDescription(answer)}
                        </motion.div>
                        {answer.answer && categoryName !== 'Оценка по компетенциям' && categoryName !== 'Итоговая оценка' &&
                            <TextareaAutosize
                                type="text"
                                value={answer.comment || ''}
                                maxRows={15}
                                minLength={15}
                                onChange={(e) => handleCommentChange(answer.question_id, e)}
                                placeholder={isYours || isInspector ? `Прокомментируйте выбор оценки` : 'Сотрудник не оставил комментарий'}
                                required={isYours && answer.answer !== '3.0'}
                                className="question-item__input"
                                disabled={!isYours && !isInspector}
                            />
                        }
                    </React.Fragment>
                );
            })}
        </div>
    );
}

export default QuestionItem;
