import React, { useState, useEffect, useRef } from 'react';
import api from '../../utils/Api';
import './EmployeeSearchModule.css';
import { useNavigate } from 'react-router-dom';
import arrow_left from '../../utils/images/arrow_left.svg';
import search from '../../utils/images/search.svg';
import useUserStore from '../../utils/stores/useUserStore';
import { EmployeeSearchSelect, MiniSearchSelect } from './EmployeeSearchSelect';
import { motion } from 'framer-motion';
import SearchListItem from './SearchListItem';
import LoadingComponent from '../../utils/LoadingComponent';
import MassCalibratePopup from './MassCalibratePopup';

function EmployeeSearchModule() {
    const [results, setResults] = useState([]);
    const [departmentList, setDepartmentList] = useState(null);
    const [managerList, setManagerList] = useState(null);
    const [selectedManagers, setSelectedManagers] = useState([]);
    const [selectedDepartments, setSelectedDepartments] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [isCalibratePopupOpened, setCalibratePopupOpened] = useState(false);
    const navigate = useNavigate();
    const { buId, isLoading, setIsLoading } = useUserStore();

    const statusQuiz = [
        { value: "Создана", label: "незаполненные" },
        { value: "Оценен сотрудником", label: "заполнена сотрудником" },
        { value: "Оценен руководителем", label: "требует калибровки" },
        { value: "Завершен", label: "калибровка завершена" },
        { value: "", label: "все" },
    ];

    const filterNullParams = params => {
        return Object.fromEntries(Object.entries(params).filter(([_, v]) => v != null));
    };

    function extractValuesByKey(arr, key) {
        return arr?.map(item => item[key]);
    }

    const getParamsString = (paramsArray) => {
        return paramsArray.map(item => item.value).join(', ');
    };

    const searchQuizzes = async (e) => {
        if (e) e.preventDefault();
        const token = localStorage.getItem('jwt');
        try {
            setIsLoading(true);
            const filteredParams = filterNullParams({
                status_doc_quiz__name: searchParams.status_doc_quiz__name,
                staff__BU: extractValuesByKey(buId, "bu_id"),
                inspector_id: getParamsString(selectedManagers),
                staff__department: getParamsString(selectedDepartments),
                search: inputValue,
                page: page,
            });
            const response = await api.getQuizes(token, filteredParams);
            setResults(prevResults => [...prevResults, ...response.results]);
            setTotalPages(Math.ceil(response.count / 50));
        } catch (error) {
            console.error('Error fetching quizzes:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearchChange = (field, value) => {
        setSearchParams(prevParams => ({
            ...prevParams,
            [field]: value,
        }));
        setPage(1);
        setResults([]);
    };

    const [searchParams, setSearchParams] = useState({
        status_doc_quiz__name: null,
        staff__BU: extractValuesByKey(buId, "bu_id") || null,
        quarter: null,
        inspector_id: getParamsString(selectedManagers)
    });

    useEffect(() => {
        document.title = 'Pridex Plus | Поиск сотрудника';
    }, []);

    useEffect(() => {
        const getDepartments = async () => {
            try {
                setIsLoading(true);
                const token = localStorage.getItem('jwt');
                const res = await api.getDepartmentsByBuId(token, extractValuesByKey(buId, "bu_id"));
                const depList = res.departments
                    .filter(department => department != null && department !== '')
                    .map(department => ({
                        value: department,
                        label: department
                    }));
                setDepartmentList(depList);
            } catch (error) {
                console.error('Error fetching departments:', error);
            } finally {
                setIsLoading(false);
            }
        };

        const getManagers = async () => {
            try {
                setIsLoading(true);
                const token = localStorage.getItem('jwt');
                const res = await api.getManagersByBuId(token, extractValuesByKey(buId, "bu_id"));
                const manList = res.managers
                    .filter(manager => manager.manager_id != null && manager.manager_full_name != null)
                    .map(manager => ({
                        value: manager.manager_id,
                        label: manager.manager_full_name,
                    }));
                setManagerList(manList);
            } catch (error) {
                console.error('Error fetching managers:', error);
            } finally {
                setIsLoading(false);
            }
        };

        getDepartments();
        getManagers();
    }, []);

    useEffect(() => {
        setSearchParams({
            staff__BU: extractValuesByKey(buId, "bu_id"),
            inspector_id: getParamsString(selectedManagers),
            staff__department: getParamsString(selectedDepartments)
        });
        setPage(1);
        setResults([]);
    }, [selectedManagers, selectedDepartments, buId]);

    // useEffect(() => {
    //     const params = new URLSearchParams(filterNullParams({
    //         status_doc_quiz__name: searchParams.status_doc_quiz__name,
    //         staff__BU: buId,
    //         inspector_id: getParamsString(selectedManagers),
    //         staff__department: getParamsString(selectedDepartments),
    //         search: inputValue,
    //         page: page > 1 ? page : null,
    //     })).toString();
    //     window.history.pushState({}, '', `?${params}`);
    // }, [searchParams, inputValue, page]);

    // useEffect(() => {
    //     const params = new URLSearchParams(window.location.search);

    //     const parsedParams = {
    //         status_doc_quiz__name: params.get('status_doc_quiz__name'),
    //         staff__BU: params.get('staff__BU'),
    //         inspector_id: params.get('inspector_id')?.split(',') || [],
    //         staff__department: params.get('staff__department')?.split(',') || [],
    //         search: params.get('search') || '',
    //         page: parseInt(params.get('page')) || 1,
    //     };
    //     setSearchParams({
    //         status_doc_quiz__name: parsedParams.status_doc_quiz__name,
    //         staff__BU: parsedParams.staff__BU,
    //         inspector_id: parsedParams.inspector_id.join(', '),
    //         staff__department: parsedParams.staff__department.join(', '),
    //     });
    //     setSelectedManagers(parsedParams.inspector_id.map(id => ({ value: id, label: id })));
    //     setSelectedDepartments(parsedParams.staff__department.map(dept => ({ value: dept, label: dept })));
    //     setInputValue(parsedParams.search);
    //     setPage(parsedParams.page);
    // }, []);

    const lastItem = useRef();

    const actionInSight = (entries) => {
        if (entries[0].isIntersecting && page < totalPages) {
            setPage(prevPage => prevPage + 1);
        }
    };

    const observerLoader = useRef();

    useEffect(() => {
        if (observerLoader.current) {
            observerLoader.current.disconnect();
        }

        observerLoader.current = new IntersectionObserver(actionInSight);

        if (lastItem.current) {
            observerLoader.current.observe(lastItem.current);
        }
    }, [lastItem, results]);

    useEffect(() => {
        if (page > 1) {
            searchQuizzes();
        }
    }, [page]);

    useEffect(() => {
        setIsLoading(true);
        searchQuizzes();
    }, [])

    return (
        <>
            <div className='employee-search__div'>
                <button className="quiz-module__button-div" onClick={() => navigate('/profile')} style={{ top: '-45px' }}>
                    <img className="quiz-module__button" src={arrow_left} alt="" />
                    <p>В профиль</p>
                </button>
                <form className='employee-search__row' id='empl-first-row' onSubmit={(e) => {
                    setResults([]);
                    searchQuizzes(e)
                }}>
                    <input
                        className='employee-search__input'
                        type="text"
                        placeholder="Введите ФИО..."
                        value={inputValue}
                        onChange={(e) => {
                            setInputValue(e.target.value);
                            setPage(1);
                            setResults([]);
                        }}
                    />
                    <button className='employee-search__button' type='submit'>
                        <img src={search} alt='Search Icon' />
                    </button>
                </form>
                <div className='employee-search__row' id='empl-second-row'>
                    <EmployeeSearchSelect
                        placeholder='По отделу'
                        onChange={selectedOption => {
                            handleSearchChange('staff__BU', selectedOption?.value);
                            setSelectedDepartments(selectedOption ? [selectedOption] : []);
                        }}
                        options={departmentList}
                        selectedOptions={selectedDepartments}
                        onSelectedChange={setSelectedDepartments}
                    />
                    <EmployeeSearchSelect
                        placeholder='По руководителю'
                        options={managerList}
                        selectedOptions={selectedManagers}
                        onSelectedChange={setSelectedManagers}
                    />
                </div>

                <div className='employee-search__row' id='empl-second-row' style={{ height: '50px' }}>
                    <div className='employee-search__select-div'>
                        <p>Состояние оценки:</p>
                        <MiniSearchSelect
                            placeholder='выберите'
                            onChange={selectedOption => handleSearchChange('status_doc_quiz__name', selectedOption?.value)}
                            options={statusQuiz}
                        />
                    </div>
                </div>
            </div>

            <div className='employee-search__main-div'>
                <div className='employee-search__main-header'>
                    <p>ФИО / Должность</p>
                    <p>Отдел / Руководитель</p>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                        <p>Сотрудник</p>
                        <p>Руководитель</p>
                    </div>
                    <button className='employee-search__calibrate-button' onClick={() => setCalibratePopupOpened(true)}>Массовая калибровка</button>
                </div>
                {isLoading && page === 1 ? (
                    <LoadingComponent />
                ) : (
                    results.length > 0 ? (
                        <motion.div className='employee-search__list'>
                            {results.map((result, index) => (
                                index + 1 === results.length ? (
                                    <SearchListItem item={result} key={result.id} ref={lastItem} />
                                ) : (
                                    <SearchListItem item={result} key={result.id} />
                                )
                            ))}
                        </motion.div>
                    ) : (
                        !isLoading && (
                            <p style={{ height: '80px', display: 'flex', alignItems: 'center' }}>Поиск не дал результатов. Выберите новые значения фильтров и/или нажмите кнопку поиска.</p>
                        )
                    )
                )}
                {isLoading && page > 1 && <LoadingComponent />}
            </div>
            <MassCalibratePopup isOpened={isCalibratePopupOpened} setIsOpened={setCalibratePopupOpened} />
        </>
    );
}

export default EmployeeSearchModule;
