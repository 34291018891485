import { useEffect } from "react";
import useQuizStore from "../../utils/stores/useQuizStore";
import SummaryAnswerItem from "../InspectorQuizModule/AnswerItem/SummaryAnswerItem";
import './CalibrateQuizModule.css';
import NotEditableQuestionItem from "../QuizModule/utils/NotEditableQuestionItem";

import SummaryEditableQuestionItem from "./SummaryEditableQuestionItem";
function CalibrateQuizModule({ handleSubmit }) {
    const { mergedInspectorQuiz, groupedInspectorQuiz, isDirty, quiz } = useQuizStore();

    return (
        <>

            <SummaryEditableQuestionItem />
            {isDirty ? <button className="quiz-body__button" type="submit" onClick={handleSubmit}>Сохранить</button> : null}
            {groupedInspectorQuiz
                ?.filter(item => item.category_name === 'Оценка по компетенциям')
                .map((item, index) => {
                    return <NotEditableQuestionItem key={index} item={item} />;
                })}
            {mergedInspectorQuiz
                ?.filter(item => item.category_name !== 'Оценка по компетенциям' && item.category_name !== 'Итоговая оценка')
                .map((item, index) => {
                    return <SummaryAnswerItem key={`${item.category_name}-${index}`} item={item} />;
                })}
            <div className="answer-item">
                <div className="question-header">
                    <p>Итоговый комментарий сотруднику</p>
                </div>
                {
                    quiz?.comment?.length > 0 ? (
                        <div className="question-comment">
                            <p style={{ color: '#666666' }}>— {quiz.full_name_inspector}</p>
                            <p>{quiz.comment}</p>
                        </div>
                    ) : (
                        <div className="question-comment">
                            <p>Руководитель не оставил комментарий</p>
                        </div>
                    )
                }
            </div>
        </>
    );
}

export default CalibrateQuizModule;
