import React, { useEffect, useState, useRef } from 'react';
import './BeforeExitPopup.css';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import useQuizStore from '../../../utils/stores/useQuizStore';

function BeforeExitPopup({ isPopupOpened, setPopupOpened }) {
    const navigate = useNavigate();
    const popupRef = useRef();

    const { setDirty, isDirty } = useQuizStore();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setPopupOpened(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.documentElement.classList.remove('body-scroll-lock');
        };
    }, [setDirty, setPopupOpened]);

    useEffect(() => {
        if (isPopupOpened) {
            document.documentElement.classList.add('body-scroll-lock');
        } else {
            document.documentElement.classList.remove('body-scroll-lock');
        }
    }, [isPopupOpened]);

    return (
        <>
            {isPopupOpened &&
                (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ type: 'spring', duration: .7 }}
                        className="before-exit-popup__layout">
                        <div ref={popupRef} className="before-exit-popup__body">
                            <p className='before-exit-popup__title'>У вас остались несохраненные данные. При возвращении в профиль, оценки сбросятся.</p>
                            <p className='before-exit-popup__subtitle'>Вы уверены что хотите выйти?</p>
                            <div className='before-exit-popup__button-div'>
                                <button onClick={() => {
                                    setDirty(false);
                                    navigate('/profile')
                                }} className='before-exit-popup__button_grey'>Выйти</button>
                                <button onClick={() => {
                                    setPopupOpened(false);
                                }} className='before-exit-popup__button'>
                                    Продолжить
                                </button>
                            </div>
                        </div>
                    </motion.div>
                )
            }
        </>
    );
}

export default BeforeExitPopup;
