import { useEffect, useRef, useState } from 'react';
import './CreateQuizModule.css';
import api from '../../utils/Api';
import { motion } from 'framer-motion';
import cross from '../../utils/images/cross.svg';
import LoadingComponent from '../../utils/LoadingComponent';

function CreateQuizPopup({ isPopupOpened, setPopupOpened, type, setRespondent, setEmployee }) {
    const popupRef = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [typingTimeout, setTypingTimeout] = useState(null);
    const [results, setResults] = useState([]);
    const [query, setQuery] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        if (!query) {
            setResults([]);
        }
    }, [query]);

    useEffect(() => {
        if (isPopupOpened) {
            document.documentElement.classList.add('body-scroll-lock');
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.documentElement.classList.remove('body-scroll-lock');
            document.removeEventListener('mousedown', handleClickOutside);
        }
        setQuery('');
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.documentElement.classList.remove('body-scroll-lock');
        };
    }, [isPopupOpened]);

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setPopupOpened(false);
        }
    };

    const handleInputChange = (event) => {
        const inputVal = event.target.value;
        setQuery(inputVal);
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        if (inputVal.trim() !== '') {
            setTypingTimeout(setTimeout(() => {
                searchWithPagination(inputVal);
            }, 500));
        } else {
            setResults([]);
        }
    };

    const searchWithPagination = async (query) => {
        const token = localStorage.getItem('jwt');
        try {
            setIsLoading(true);
            const response = await api.searchByAnyField(token, query);
            setResults(response.results);
        } catch (error) {
            console.error('Error fetching profiles:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {isPopupOpened && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ type: 'spring', duration: .7 }}
                    className="quiz__create-popup__layout"
                >
                    <div ref={popupRef} className="change-respondent-popup__body">
                        <div className='change-respondent-popup__title-div'>
                            <p className="change-respondent-popup__title">Выбор {type === 'user' ? 'сотрудника' : 'оценивающего сотрудника'}</p>
                            <button className='change-respondent-popup__close-button' onClick={() => setPopupOpened(false)}>
                                <img src={cross} alt="Close"></img>
                            </button>
                        </div>
                        <input onChange={(e) => handleInputChange(e)} placeholder='Начните вводить имя...'></input>
                        <div className="change-respondent-popup__list">
                            {!isLoading ?
                                results.slice(0, 5).map((result, index) => (
                                    <div className={`change-respondent__item${selectedItem === result ? '_selected' : ''}`} key={result.id} onClick={() => setSelectedItem(result)}>
                                        <p style={{ width: '60%', maxWidth: '290px', textAlign: 'left' }} title={`${result.last_name} ${result.first_name} ${result.surname}`} >{result.last_name} {result.first_name} {result.surname}</p>
                                        <p style={{ width: '40%', maxWidth: '335px', textAlign: 'left' }} title={`${result.position}`}>{result.position ? result.position : '—'}</p>
                                    </div>
                                ))
                                : <LoadingComponent />}
                        </div>
                        <button className='change-respondent-popup__button' onClick={() => {
                            if (type === 'user') {
                                setEmployee(selectedItem);
                            } else {
                                setRespondent(selectedItem);
                            }
                            setPopupOpened(false);
                        }
                        }>Выбрать</button>
                    </div>
                </motion.div >
            )
            }
        </>
    );
}

export default CreateQuizPopup;
