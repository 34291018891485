import { Outlet, Navigate } from 'react-router-dom'
import LoadingComponent from './LoadingComponent'
import ErrorComponent from './ErrorComponent'

const PrivateRoutes = ({ isLoggedIn, loadingError, isLoading }) => {

  if (isLoading) {
    return  <LoadingComponent />
  }

  if (loadingError) {
    return <ErrorComponent />
  }

  return (
    isLoggedIn ? <Outlet /> : <Navigate to="/sign-in" />
  )
}

export default PrivateRoutes