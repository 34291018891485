import React, { useEffect, useState } from 'react';
import './AuthModule.css';
import { motion } from 'framer-motion';
import PhoneLogin from './PhoneLogin/PhoneLogin';
import UsernameLogin from './UsernameLogin/UsernameLogin';

export default function AuthModule({ setLoggedIn, setUserProfile, setLoadingError }) {
    const [selectedLoginType, setSelectedLoginType] = useState('username');

    useEffect(() => {
        document.title = "Pridex Plus | Вход";
    }, []);

    return (
        <>
            <div className='auth-block circe'>
                    {/* <motion.div className='auth-block__switcher'>
                        <div
                            className={selectedLoginType == 'username' ? 'auth-block__switcher__item auth-block__switcher__item_active' : 'auth-block__switcher__item'}
                            onClick={() => setSelectedLoginType('username')}
                        >
                            По логину
                        </div>
                        <div
                            className={selectedLoginType == 'phone' ? 'auth-block__switcher__item auth-block__switcher__item_active' : 'auth-block__switcher__item'}
                            onClick={() => setSelectedLoginType('phone')}
                        >
                            По телефону
                        </div>
                    </motion.div>
                    {selectedLoginType === 'username' ? (
                        <UsernameLogin setLoggedIn={setLoggedIn} setUserProfile={setUserProfile} setLoadingError={setLoadingError} />
                    ) : (
                        <>
                            <PhoneLogin setLoggedIn={setLoggedIn} setUserProfile={setUserProfile} setLoadingError={setLoadingError} />
                        </>
                    )} */}
                    <PhoneLogin setLoggedIn={setLoggedIn} setUserProfile={setUserProfile} setLoadingError={setLoadingError} />
            </div>
        </>
    );
}
