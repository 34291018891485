import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MiniSearchSelect } from "./EmployeeSearchSelect";
import cross from '../../utils/images/cross.svg';
import api from '../../utils/Api';
import useUserStore from '../../utils/stores/useUserStore';
import success from '../../utils/images/success.svg';
function MassCalibratePopup({ isOpened, setIsOpened }) {
    function getQuarterDate(quarter) {
        const currentYear = new Date().getFullYear();
        const quarterDates = {
            1: '-03-31',
            2: '-06-30',
            3: '-09-30',
            4: '-12-15'
        };
        const date = `${currentYear}${quarterDates[quarter]}`;

        return date;
    }
    const popupRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsOpened(false)
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [navigate]);

    useEffect(() => {
        if (isOpened) {
            document.documentElement.classList.add('body-scroll-lock');
        } else {
            document.documentElement.classList.remove('body-scroll-lock');
        }
    }, [isOpened]);

    useEffect(() => {
        if (isOpened) {
            document.documentElement.classList.add('body-scroll-lock');
        } else {
            document.documentElement.classList.remove('body-scroll-lock');
        }
        return () => {
            document.documentElement.classList.remove('body-scroll-lock');
        };
    }, [isOpened]);

    const [selectedQuarter, setSelectedQuarter] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const { buId, userProfile } = useUserStore();

    function extractValuesByKey(arr, key) {
        return arr.map(item => item[key]);
    }

    const calibrateAllQuizes = async () => {
        try {
            setIsLoading(true);
            const token = localStorage.getItem('jwt');
            const res = api.calibrateMassQuiz(token, extractValuesByKey(buId, "bu_id"), getQuarterDate(selectedQuarter), userProfile.id);
            setSuccess(true);
        } catch (error) {
            console.log(error);
            setSuccess(false);
        } finally {
            setIsLoading(false);
        }
    }

    const options = [
        { value: 1, label: "1 квартал" },
        { value: 2, label: "2 квартал" },
        { value: 3, label: "3 квартал" },
        { value: 4, label: "4 квартал" },
    ]

    return (
        <>
            {isOpened &&
                (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ type: 'spring', duration: .7 }}
                        className="calibrate-popup__layout">
                        <div
                            ref={popupRef}
                            className="calibrate-popup__body">
                            <button className='calibrate-popup__close-button' onClick={() => setIsOpened(false)}>
                                <img src={cross} alt="Close"></img>
                            </button>
                            {!isSuccess &&
                                <>
                                    <p className="calibrate-popup__title">Массовая калибровка</p>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '15px' }}>
                                        <p>Выбранный квартал:</p>

                                        <MiniSearchSelect
                                            placeholder="Выберите"
                                            options={options}
                                            onChange={selectedOption => setSelectedQuarter(selectedOption?.value)} />
                                    </div>
                                    <p>Учтите, что нажатие на кнопку, откалибрует все анкеты за указанный квартал текущего года.
                                        Под калибровку попадают анкеты относящиеся к вашему Бизнес юниту.
                                        Вы соглашаетесь с оценкой руководителя. Если её нет, значения останутся пустыми.
                                    </p>
                                </>
                            }
                            {isLoading && <p>Процесс занимает некоторое время, не закрывайте вкладку до завершения.</p>}
                            {isSuccess &&
                                <>
                                    <p className="calibrate-popup__title">Массовая калибровка</p>
                                    <img src={success}></img>
                                    <p className="quiz__create__success">Калибровка завершена!</p>
                                </>
                            }
                            {!isSuccess &&
                                <button className="calibrate-popup__submit-button"
                                    disabled={!selectedQuarter || isLoading}
                                    onClick={() => calibrateAllQuizes()}
                                >{isLoading ? 'Загрузка...' : 'Откалибровать'}</button>}
                        </div>
                    </motion.div>
                )
            }
        </>
    );
};

export default MassCalibratePopup;