
import React from 'react';
import Select from 'react-select';

export const EmployeeSearchSelect = ({ placeholder, options, onChange, selectedOptions, onSelectedChange, value }) => {
    const selectStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            display: 'flex',
            alignItems: 'center',
            width: 'auto',
            fontFamily: 'Circe',
            height: '40px',
            zIndex: '20',
            borderRadius: '10px',
            borderColor: '#DAF0F9',
            textAlign: 'left',
            boxShadow: state.isFocused ? 0 : 0,
            '&:hover': {
                border: state.isFocused ? '1px solid #B5E2F4' : '1px solid #B5E2F4'
            },
            opacity: state.isDisabled ? .5 : 1,
            backgroundColor: state.isDisabled ? '#fff' : '#fff',
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            borderRadius: '10px',
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: state.isSelected ? '#48849C' : '#fff',
            color: state.isSelected ? '#fff' : '#000',
            '&:hover': {
                backgroundColor: state.isSelected ? '#48849C' : '#DAF0F9'
            },
        }),
        valueContainer: (baseStyles) => ({
            ...baseStyles,
            padding: '0px',
            fontFamily: 'Circe',
            height: '40px',
            paddingLeft: '10px'
        }),
        container: (baseStyles) => ({
            ...baseStyles,
            fontFamily: 'Circe',
            width: '100%',
            height: '40px'
        }),
        placeholder: (baseStyles) => ({
            ...baseStyles,
            fontSize: '15px',
            textAlign: 'left',
            fontFamily: 'Circe',
            fontWeight: 'normal',
            color: '#000',
        }),
        singleValue: (baseStyles) => ({
            ...baseStyles,
            fontSize: '15px',
            fontFamily: 'Circe',
            height: '38px',
            marginBottom: '6px',
            borderRadius: '10px',
            color: '#000',
        }),
        menuPortal: (baseStyles) => ({
            ...baseStyles,
            zIndex: 9999,
            textAlign: 'left',
            borderColor: '#DAF0F9',
        }),
        menu: (baseStyles) => ({
            ...baseStyles,
            padding: '0px',
            zIndex: 9999,
            borderRadius: '10px',
            textAlign: 'left',
            fontFamily: 'Circe',
            fontSize: '15px',
            borderColor: '#DAF0F9',
        }),
        menuList: (baseStyles) => ({
            ...baseStyles,
            padding: '0',
        }),
        multiValueLabel: (baseStyles) => ({
            ...baseStyles,
            color: '',
        }),
        indicatorSeparator: (baseStyles) => ({
            ...baseStyles,
            display: 'none'
        }),
        dropdownIndicator: (baseStyles) => ({
            ...baseStyles,
            color: '#6BC4E8'
        }),
        ValueContainer: (baseStyles) => ({
            ...baseStyles,
            position: 'absolute !important',
        }),
    };

const sortOptions = (options) => {
    return options?.sort((a, b) => {
        const labelA = a?.label?.toString() || '';
        const labelB = b?.label?.toString() || '';
        const firstWordA = labelA.split(' ')[0];
        const firstWordB = labelB.split(' ')[0];
        return firstWordA.localeCompare(firstWordB);
    });
};


    const sortedOptions = sortOptions(options);


    const handleChange = (selectedOptions) => {
        onSelectedChange(selectedOptions);
    };


    return (
        <Select
            closeMenuOnSelect={false}
            isMulti
            styles={selectStyles}
            isSearchable={true}
            placeholder={placeholder}
            options={sortedOptions}
            value={value}
            onChange={handleChange}
        />
    )
}

export const MiniSearchSelect = ({ options, placeholder, onChange, value }) => {
    const selectStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            width: '155px',
            fontFamily: 'Circe',
            zIndex: '20',
            borderRadius: '10px',
            borderColor: '#DAF0F9',
            textAlign: 'left',
            boxShadow: state.isFocused ? 0 : 0,
            '&:hover': {
                border: state.isFocused ? '1px solid #B5E2F4' : '1px solid #B5E2F4'
            },
            opacity: state.isDisabled ? .5 : 1,
            backgroundColor: state.isDisabled ? '#fff' : '#fff',
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            borderRadius: '10px',
            height: '38px',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: state.isSelected ? '#48849C' : '#fff',
            color: state.isSelected ? '#fff' : '#000',
            '&:hover': {
                backgroundColor: state.isSelected ? '#48849C' : '#DAF0F9'
            },
        }),
        valueContainer: (baseStyles) => ({
            ...baseStyles,
            padding: '0px',
            fontFamily: 'Circe',
            paddingLeft: '10px'
        }),
        container: (baseStyles) => ({
            ...baseStyles,
            fontFamily: 'Circe',
        }),
        placeholder: (baseStyles) => ({
            ...baseStyles,
            fontSize: '15px',
            textAlign: 'left',
            fontFamily: 'Circe',
            fontWeight: 'normal',
            color: '#000',
        }),
        singleValue: (baseStyles) => ({
            ...baseStyles,
            fontSize: '15px',
            fontFamily: 'Circe',
            marginBottom: '0px',
            borderRadius: '10px',
            color: '#000',
        }),
        menuPortal: (baseStyles) => ({
            ...baseStyles,
            zIndex: 9999,
            textAlign: 'left'
        }),
        menu: (baseStyles) => ({
            ...baseStyles,
            width: '200px',
            padding: '0px',
            borderRadius: '10px',
            textAlign: 'left',
            zIndex: 9999,
            fontFamily: 'Circe',
            fontSize: '15px',
            borderColor: '#B3B3B3',
        }),
        menuList: (baseStyles) => ({
            ...baseStyles,
            padding: '0',
            borderColor: '#B3B3B3',
        }),
        multiValueLabel: (baseStyles) => ({
            ...baseStyles,
            color: '',
        }),
        indicatorSeparator: (baseStyles) => ({
            ...baseStyles,
            display: 'none'
        }),
        dropdownIndicator: (baseStyles) => ({
            ...baseStyles,
            color: '#6BC4E8'
        }),
    };



    return (
        <Select
            options={options}
            styles={selectStyles}
            isSearchable={false}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
        />
    )
}
