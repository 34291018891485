import React, { useEffect, useState } from 'react';
import './HRModule.css';
import excel from '../../utils/images/excel.svg';
import search_rofl from '../../utils/images/search_rofl.svg';
import quiz from '../../utils/images/quiz.svg';
import { useNavigate } from 'react-router-dom';
import api from '../../utils/Api';
import useUserStore from '../../utils/stores/useUserStore';
function HRModule() {
    const navigate = useNavigate();

    const { isLoading, userProfile } = useUserStore();
    const hasRole = (roleName) => userProfile?.roles?.some(role => role.name === roleName);
    if (isLoading) return null;

    return (
        <>
            <div className="hr-module__div">
                <button className='hr-module__button' onClick={() => navigate('/employee-search')}>
                    <p>Поиск сотрудника</p>
                    <img src={search_rofl} alt="Search Icon" />
                </button>
                <button className='hr-module__button' onClick={() => navigate('/report')}>
                    <p>Выгрузка отчёта</p>
                    <img src={excel} alt="Excel Icon" />
                </button>
                <button className='hr-module__button' onClick={() => navigate('/create-quiz')}>
                    <p>Рассылка анкет</p>
                    <img src={quiz} alt="Quiz Icon" />
                </button>
            </div>
        </>
    )
}

export default HRModule;
