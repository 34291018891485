import { useState, useEffect } from 'react';

export const answerValuesToLetters = {
    '5.0': 'A',
    '4.0': 'B',
    '3.0': 'C',
    '1.5': 'D',
    '0.0': 'E'
};


export const renderAnswer = (answer) => {
    const answerKey = parseFloat(answer).toFixed(1);
    return answerValuesToLetters[answerKey] || '?';
};



const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + (days - 1));
    return result;
};

export const calculateCurrentPeriod = (quiz, settings) => {
    if (!quiz || !settings) {
        return {
            currentPeriod: 'create',
            deadlineStaffDate: null,
            deadlineInspectorDate: null,
            deadlineCalibrationDate: null,
        };
    }

    const creationDate = new Date(quiz.time_create);
    const daysForStaff = parseInt(settings.find(item => item.key === 'days_for_staff')?.value, 10);
    const daysForInspector = parseInt(settings.find(item => item.key === 'days_for_inspector')?.value, 10);
    const daysForCalibration = parseInt(settings.find(item => item.key === 'days_for_calibration')?.value, 10);

    if (isNaN(daysForStaff) || isNaN(daysForInspector) || isNaN(daysForCalibration)) {
        return {
            currentPeriod: 'create',
            deadlineStaffDate: null,
            deadlineInspectorDate: null,
            deadlineCalibrationDate: null,
        };
    }

    const currentDate = new Date();

    const deadlineStaffDate = addDays(creationDate, daysForStaff);
    const deadlineInspectorDate = addDays(creationDate, daysForStaff + daysForInspector);
    const deadlineCalibrationDate = addDays(creationDate, daysForStaff + daysForInspector + daysForCalibration);

    let currentPeriod = 'create';

    if (currentDate <= deadlineStaffDate) {
        currentPeriod = 'staff';
    } else if (currentDate <= deadlineInspectorDate) {
        currentPeriod = 'inspector';
    } else if (currentDate <= deadlineCalibrationDate) {
        currentPeriod = 'calibration';
    } else if (deadlineCalibrationDate <= currentDate) {
        currentPeriod = 'calibration'
    }

    const formatDate = (date) => {
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    return {
        currentPeriod,
        deadlineStaffDate: formatDate(deadlineStaffDate),
        deadlineInspectorDate: formatDate(deadlineInspectorDate),
        deadlineCalibrationDate: formatDate(deadlineCalibrationDate),
    };
};





