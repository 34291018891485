import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useRef } from 'react';

function DescriptionPopup({ description, isPopupOpened, setPopupOpened, index }) {
    const popupRef = useRef();

    const lettersArray = ['A', 'B', 'C', 'D', 'E'];

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setPopupOpened(index, false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setPopupOpened, index]);

    return (
        <AnimatePresence>
            {isPopupOpened &&
                <div>
                    <motion.div
                        ref={popupRef}
                        className='description-popup'
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        key={`popupdesc${index}`}
                    >
                        {description.map((item, i) => (
                            <div className='description-popup__row' key={i}>
                                <div className='description-popup__letter'>{lettersArray[i]}</div>
                                <div className='description-popup__text-block'>{item}</div>
                            </div>
                        ))}
                    </motion.div>
                </div>
            }
        </AnimatePresence>
    );
}

export default DescriptionPopup;
