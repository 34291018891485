import { useState } from 'react';
import './QuickQuizModule.css';

function QuickQuizModule() {
    const [selectedOption, setSelectedOption] = useState(null);

    return (
        <>
            <p className="quick-quiz__title">Выберите опцию</p>
            <div className="quick-quiz__actions__div">
                <p className="quick-quiz__actions__title">Выбранные опции применяются ко всем сотрудникам в списке. Вы можете опционально изменить ее в списке ниже.
                    Учтите, что выбранная оценка ставится по каждой категории оценивания. </p>
                <div className="quick-quiz__actions__buttons">
                    <button className="quick-quiz__actions__button" disabled>По средней предыдущей оценке</button>
                    <button className={`quick-quiz__actions__button ${selectedOption === 'setScoreC' ? + '_active' : null}`} onClick={() => setSelectedOption('setScoreC')}>Поставить "C"</button>
                    <button className={`quick-quiz__actions__button ${selectedOption === 'setEmplScore' ? + '_active' : null}`} onClick={() => setSelectedOption('setEmplScore')}>Согласиться с оценкой сотрудника</button>
                </div>
            </div>
            <div className="quick-quiz__list__div">
                <div className="quick-quiz__list__header">
                    <div className="quick-quiz__list__column">ФИО</div>
                    <div className="quick-quiz__list__column">Должность</div>
                    <div className="quick-quiz__list__column">Прошлая оценка</div>
                    <div className="quick-quiz__list__column">Выбранная оценка</div>
                </div>
                <div className="quick-quiz__list__body">

                </div>
            </div>
        </>
    )






}

export default QuickQuizModule;