import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import './QuizStatusBar.css';
import edit from '../../utils/images/edit.svg';
import LoadingComponent from '../../utils/LoadingComponent';
import api from '../../utils/Api';
import { useNavigate } from 'react-router-dom';
import avatar_nan from '../../utils/images/avatar.svg';
import { formatDate, GetDeadlineDate } from '../../utils/TimeConvertFunctions';
import useQuizStore from '../../utils/stores/useQuizStore';
import useUserStore from '../../utils/stores/useUserStore';
import { current } from '@reduxjs/toolkit';
import { QuizStatusBarSelect } from './QuizStatusBarSelect';
import { statusDocQuiz } from '../../utils/Constants';

function QuizStatusBar({ setRespondentOpened }) {
    const [respondent, setRespondent] = useState(null);
    const [quizUser, setQuizUser] = useState(null);
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(window.location.search);
    const calibrate = queryParams.get("calibrate");

    const { groupedStaffQuiz, quiz, isYours, isInspector, setIsLoading, isLoading, deadlineDateInspector, deadlineDateStaff, currentPeriod } = useQuizStore();
    const { userProfile } = useUserStore();
    const hasRole = (roleName) => userProfile.roles.some(role => role.name === roleName);

    const initialStatus = statusDocQuiz.find(option => option.value === quiz.status_doc_quiz);
    const [quizStatus, setQuizStatus] = useState(initialStatus);

    // useEffect(() => {
    //     handleChangeQuizStatus();
    // }, [quizStatus]);

    const patchCalibrateQuiz = async () => {
        try {
            setIsLoading(true);
            const token = localStorage.getItem('jwt');
            const res = api.patchCalibrateQuiz(token, quiz.id, userProfile.id);
            console.log(res);
        } catch (error) {
            console.log(error);
        } finally {
            setTimeout(() => {
                setIsLoading(false);
                navigate(`/quiz/${quiz.id}?calibrate=true`);
                navigate(0);
            }, 1000);
        }
    };

    useEffect(() => {
        const fetchRespondent = async () => {
            try {
                setIsLoading(true);
                const token = localStorage.getItem('jwt');
                const res = await api.fetchProfileByProfileId(token, quiz.inspector_id);
                const user = await api.fetchProfileByProfileId(token, quiz.staff_id);
                setRespondent(res);
                setQuizUser(user);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchRespondent();
    }, [quiz?.id]);

    const handleChangeQuizStatus = async (e) => {
        if (!quizStatus) return;
        try {
            const statusId = e.value;
            const token = localStorage.getItem('jwt');
            const res = api.patchQuizStatus(token, quiz.id, statusId);
            console.log(res);
        } catch (error) {
            console.log(error);
        } finally {
            setQuizStatus(e);
        }
    };

    function convertDate(inputDate) {
        const date = new Date(inputDate);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;

        let quarter;
        if (month <= 3) quarter = 'I';
        else if (month <= 6) quarter = 'II';
        else if (month <= 9) quarter = 'III';
        else quarter = 'IV';

        return `${quarter} квартал ${year}`;
    }

    const colorMatch = {
        "Создана": {
            color: "red",
            text: "Не пройдена"
        },
        "Оценен сотрудником": {
            color: "#003B73",
            text: "Пройдена сотрудником"
        },
        "Оценен руководителем": {
            color: "#2C7865",
            text: "Оценен руководителем"
        },
        "Завершен": {
            color: "green",
            text: "Оценка завершена"
        }
    };

    if (!quiz) return null;

    return (
        <motion.div
            className='quiz__statusbar__container'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            <p className='quiz__statusbar__title'>Ежеквартальная оценка результативности</p>
            <p className='quiz__statusbar__title' style={{ fontWeight: 'bold' }}>{convertDate(quiz.quarter)}</p>
            <div className="quiz__statusbar">
                <div className='quiz__statusbar__user-block'>
                    <img style={{ width: '75px', height: '75px' }} src={avatar_nan} alt="User Avatar"></img>
                    <div className='quiz__statusbar__name-block'>
                        <p style={{ fontSize: '18px', fontWeight: 'bold' }}>{quiz.full_name}</p>
                        <p>{quiz.staff_department}</p>
                        <p>{quiz.staff_position}</p>
                    </div>
                </div>
                <div className='quiz__statusbar__date-block'>
                    {userProfile?.roles[0]?.name === 'HR'
                        ?
                        <div className='quiz__statusbar__select-div'>
                            <p>Статус: </p>
                            <QuizStatusBarSelect
                                onChange={handleChangeQuizStatus}
                                options={statusDocQuiz} 
                                value={quizStatus}
                                placeholder='Выберите статус...'
                            />
                        </div>
                        :
                        <p>Статус анкеты: <span style={{ color: colorMatch[quiz.status_doc_quiz_name]?.color, fontWeight: 'bold' }}>
                            {colorMatch[quiz.status_doc_quiz_name]?.text}</span>
                            </p>
                    }
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <p>Дата рассылки: {formatDate(quiz.time_create)}</p>
                        {quiz.status_doc_quiz_name === 'Завершен' ?
                            <p>Дата завершения: {formatDate(quiz.time_finishing)}</p>
                            :
                            <p>Дата окончания: {formatDate(isInspector || !isYours ? deadlineDateInspector : deadlineDateStaff)}</p>
                        }
                        <p>Руководитель: {quiz.full_name_inspector}</p>
                    </div>
                </div>
            </div>

            {userProfile?.roles && userProfile.roles[0]?.name === 'HR' && !isYours && (
                <div className="quiz__statusbar_add">
                    <div className='quiz__statusbar__respondent-div' title='Вы можете поменять руководителя в этой анкете'>
                        <p className='quiz__statusbar__status-p' style={{ width: 'auto' }} onClick={() => setRespondentOpened(true)}>Руководитель: <span>{respondent && `${respondent.last_name} ${respondent.first_name} ${respondent.surname}`}</span></p>
                        <img src={edit} alt="Edit" className='quiz__statusbar__status-img' onClick={() => setRespondentOpened(true)}></img>
                    </div>
                    {(userProfile.roles && hasRole('HR') && currentPeriod === 'calibration') ?
                        <button className='quiz__statusbar__button' onClick={() => patchCalibrateQuiz()}>
                            {isLoading ? 'Загрузка...' : 'Калибровать'}
                        </button>
                        : null
                    }
                </div>
            )}
        </motion.div>
    );
}

export default QuizStatusBar;
