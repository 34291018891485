import './QuickActions.css';
import history from '../../utils/images/history.svg'
import quick_menu from '../../utils/images/quick-menu.svg';
import quick_quiz from '../../utils/images/quick-quiz.svg';
import { useNavigate } from 'react-router-dom';
function QuickActions({ display, userProfile }) {
    const navigate = useNavigate();

    return (
        <>
            {display ?
                <div className="quick-actions__div">
                    <button className="quick-actions__button" disabled title='Функция временно отключена'>
                        <img className="quick-actions__img" src={history}></img>
                    </button>
                    <button className="quick-actions__button" title='Функция временно отключена' disabled onClick={() => navigate(`/quiz/quick-quiz/${userProfile.id}`)}>
                        <img className="quick-actions__img" src={quick_menu}></img>
                    </button>
                    <button className="quick-actions__button" title='Функция временно отключена' disabled>
                        <img className="quick-actions__img" src={quick_quiz}></img>
                    </button>
                </div>
                : null}
        </>
    );

}

export default QuickActions;