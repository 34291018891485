export class Auth {
    constructor(options) {
        this._mode = options.mode;
        this._baseUrl = options.baseUrl;
        this._headers = options.headers;
    }

    async handleLogin({ username, password }) {
        const response = await fetch(`${this._baseUrl}/auth/token/login`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ username, password })
        });
        return this._handleResponse(response);
    }

    async handleLogout(token) {
        return fetch(`${this._baseUrl}/auth/token/logout`, {
            method: 'POST',
            headers: {
                "Authorization": `Token ${token}`,
            }
        });
    }

    async handleGetCode(phone_number) {
        const response = await fetch(`${this._baseUrl}/auth_phone/`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "phone_number": `${phone_number}`
            }),
        });
        return this._handleResponse(response);
    }


    async _handleResponse(res) {
        if (res.ok) {
            return res.json();
        }
        const responseBody = await res.json();
        throw { error: new Error("Произошла ошибка"), responseBody };
    }
}

// 10.61.251.9
// https://plus.pridex.ru/

const auth = new Auth({
    baseUrl: 'https://plus.pridex.ru/api/v1',
    headers: {
        "Content-Type": "application/json",
    },
});

export default auth;
