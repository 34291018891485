import { useNavigate } from "react-router-dom";
import useUserStore from "../../utils/stores/useUserStore";
import { convertDateToQuarter, getDeadlineDateString } from "../../utils/TimeConvertFunctions";
import LoadingComponent from "../../utils/LoadingComponent";
import white_arrow from '../../utils/images/arrow_right_white.svg';
function ActualScoreModule() {
    const { userQuizList, isLoading, settings } = useUserStore();
    const navigate = useNavigate();

    const goToQuiz = (quizId) => navigate(`/quiz/${quizId}`);

    if (isLoading || !userQuizList || !settings) return <LoadingComponent />

    return (
        <>
            {userQuizList?.filter(quiz => quiz.status_doc_quiz_name !== 'Завершен').length > 0 && (
                <div className='score-module__block'>
                    <div className='score-module__header'>
                        <div className='score-module__header__col' style={{ width: '60%' }}>
                            <p style={{ width: '100%', textAlign: 'left', fontSize: '15px' }}>Актуальная анкета</p>
                        </div>
                    </div>
                    {userQuizList?.filter(quiz => quiz.status_doc_quiz_name !== 'Завершен').map((quiz) => (
                        <div className='score-module__row' key={quiz.id}>
                            <div className='score-module__status-block'>
                                {convertDateToQuarter(quiz.quarter)}
                                <div className='score-module__date-block'>
                                    {quiz.status_doc_quiz_name === 'Оценен сотрудником' 
                                    && <p>Ожидает оценки руководителя</p>}
                                    {quiz.status_doc_quiz_name === 'Создана' && <p>{getDeadlineDateString(quiz.time_create, settings?.find(item => item.key === 'days_for_staff')?.value)}</p>}
                                    {quiz.status_doc_quiz_name === 'Оценен руководителем' && <p style={{ width: '100%', textAlign: 'left', fontSize: '15px' }}>Оценка завершена</p>}
                                </div>
                            </div>
                            <a className='score-module__button' href={`/quiz/${quiz.id}`}>
                                <p>Перейти к анкете</p>
                                <img src={white_arrow} />
                            </a>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
}

export default ActualScoreModule;
